<template>
<div>
    <div class="vx-row">
        <div class="vx-col w-full mt-8">
            <vx-card id="v-step-0">
                <div class="card-title" >
                    <h2>{{ LabelConstant.headingLabelUsers }}</h2>
                    <!-- <vs-button @click="popupActive=true" color="gray" type="flat" icon-pack="feather" icon="icon-help-circle" class="p-0 help-btn"></vs-button> -->
                </div>
                <div class="add-new-number mb-2" >
                    <vs-button v-if="!vendastaIsPartner" :title="LabelConstant.buttonLabelAddNewUser" @click="addUserActive=true" color="secondary" type="filled" icon-pack="feather" icon="icon-plus" class="small-btn mr-3 sm:w-auto w-full mb-2 bg-actionbutton" :disabled="(userCount.RemainingAvailableCount < 1)?true:false">{{ LabelConstant.buttonLabelAddNewUser }}</vs-button>
                    <div class="mb-2 sm:w-auto w-full flex flex-col" v-if="!isFreeTrial">
                        <vs-button v-if="!isReseller && !isConsumerPlan" :title="LabelConstant.buttonLabelPurchaseAdditionalUsers" type="filled" class="small-btn block float-right" color="primary" :to="'/billing/billing-info/uid/'+$route.params.id+'?userpurchase=true'">{{ LabelConstant.buttonLabelPurchaseAdditionalUsers }}</vs-button>
                        <vs-button v-if="isReseller && !isConsumerPlan" :title="LabelConstant.buttonLabelPurchaseAdditionalUsers" type="filled" class="small-btn block float-right" color="primary" @click="purchaseAdditionalPopup = true">{{ LabelConstant.buttonLabelPurchaseAdditionalUsers }}</vs-button>
                        <p class="error-msg text-right w-auto" v-if="!isConsumerPlan">{{ 'Using ' +(this.userCount?this.userCount.ExistingUserCount:'')+' of ' +(this.userCount?this.userCount.TotalAvailableUsers:'') + ' available users.'}}</p>
                    </div>
                </div>
                <div class="common-table">
                    <vs-table class="user-table" :data="userinfo" ref="tableData" :max-items="perPage" pagination search>
                        <template slot="thead">
                            <vs-th>{{ LabelConstant.tableHeaderLabelOptions }}</vs-th>
                            <vs-th v-if="!vendastaIsPartner">{{ LabelConstant.tableHeaderLabelEmail }}</vs-th>
                            <vs-th>{{ LabelConstant.tableHeaderLabelFirstName }}</vs-th>
                            <vs-th >{{ LabelConstant.tableHeaderLabelLastName }}</vs-th>
                            <vs-th v-if="!vendastaIsPartner">{{ LabelConstant.tableHeaderLabelRole }}</vs-th>
                        </template>
                        <template slot-scope="{data}">
                            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                                <vs-td v-if="vendastaIsPartner" width="250">
                                    <div class="table-action">
                                        <vs-button title="Edit" type="filled" icon-pack="feather" icon="icon-edit" class="mr-2 float-left" color="primary" @click="editUser(tr.Id), getLeadHandlingTabsInfo(tr.Id), getNotificationTerritorySelectedStatesForUser(tr.Id), getNotificationTerritorySelectedCountriesForUser(tr.Id), getNotificationRule(tr.Id), getNotificationRuleUrl(tr.Id)"></vs-button>
                                        <vs-button v-if="!tr.Default_User" title="Delete" @click="deleteUser(tr.Id,tr.Email)" color="danger" type="filled" icon-pack="feather" icon="icon-trash-2" class="mr-0"></vs-button>
                                    </div>
                                </vs-td>
                                <vs-td v-if="!vendastaIsPartner">
                                    <div class="table-action">
                                        <vs-button title="Edit" type="filled" icon-pack="feather" icon="icon-edit" class="mr-2 float-left" color="primary" @click="editUser(tr.Id), getLeadHandlingTabsInfo(tr.Id), getNotificationTerritorySelectedStatesForUser(tr.Id), getNotificationTerritorySelectedCountriesForUser(tr.Id), getNotificationRule(tr.Id), getNotificationRuleUrl(tr.Id)"></vs-button>
                                        <vs-button v-if="!tr.Default_User" title="Delete" @click="deleteUser(tr.Id,tr.Email)" color="danger" type="filled" icon-pack="feather" icon="icon-trash-2" class="mr-0"></vs-button>
                                    </div>
                                </vs-td>
                                <vs-td :data="tr.Email" v-if="!vendastaIsPartner">{{tr.Email}}</vs-td>
                                <vs-td v-if="vendastaIsPartner" width="250" :data="tr.First_Name">{{tr.First_Name}}</vs-td>
                                <vs-td v-if="!vendastaIsPartner" :data="tr.First_Name">{{tr.First_Name}}</vs-td>
                                <vs-td :data="tr.Last_Name">{{tr.Last_Name}}</vs-td>
                                <vs-td :data="tr.Role_Name" v-if="!vendastaIsPartner">{{tr.Role_Name}}</vs-td>
                            </vs-tr>
                        </template>
                    </vs-table>
                    <p class="table-data-entries-details" v-if="!isSearch && isTableRecordEntriesDisplay">Showing {{showingDataFrom}} to {{showingDataTo}} of {{userinfo.length|tableRecordNumberFormatter}} entries</p>
                    <p class="table-data-entries-details" v-if="isSearch && isTableRecordEntriesDisplay">Showing {{showingDataFrom}} to {{showingSearchedDataTo}} of {{totalSearchedData|tableRecordNumberFormatter}} entries (filtered from {{userinfo.length|tableRecordNumberFormatter}} entries)</p>
                    <p class="table-data-entries-details" v-if="!isTableRecordEntriesDisplay">Showing 0 to 0 of 0 entries</p>
                </div>
                <!--Table Select Row-->

            </vx-card>
        </div>
    </div>
    <!-- Delete Popup -->
    <vs-popup class="delete-popup" :active.sync="deleteNotificationPopupActive">
        <template>
            <div class="vx-row mb-3">
                <div class="vx-col w-full">
                    <div class="popup-icon">
                        <feather-icon icon="Trash2Icon"></feather-icon>
                    </div>
                    <h4>Delete Notification URL?</h4>
                    <span class="deleted-no">{{deleteUrl}}</span>
                </div>
            </div>
        </template>
        <div class="float-right mt-6 mb-8">
            <vs-button type="filled" @click="deleteNotificationUrl()" color="danger">{{ LabelConstant.buttonLabelDelete }}</vs-button>
            <vs-button @click="deleteNotificationPopupActive = false, deleteUrl=null" color="grey" type="border" class="ml-4 grey-btn">{{ LabelConstant.buttonLabelCancel }}</vs-button>
        </div>
    </vs-popup>

    <!-- Url Modal -->
    <vs-popup class="tag-info new-notification" title="Create New Notification URL" :active.sync="addpnewurlActive">
        <form @submit.prevent="notificationRuleCheckValidation()" autocomplete="off">
            <template>
                <div class="vx-row mb-3">
                    <div class="vx-col input-text w-full">
                        <div class="popup-icon mb-10">
                            <img src="../../../src/assets/images/notification.svg" />
                        </div>
                        <p class="mb-4 mt-0">Notify me when someone visits this URL:</p>
                        <div class="http">
                            <span class="span-http">http://</span>
                            <vs-input class="w-full" placeholder="www.something.com/test.html" v-model="siteUrl" name="siteUrl" v-validate="{required:true,url:true }" />
                        </div>
                        <span class="text-danger text-sm">{{ errors.first('siteUrl') }}</span>
                    </div>
                </div>
            </template>

            <div class="flex justify-end modal-action-buttons w-full float-left mt-6 mb-8">
                <vs-button type="filled" button="submit" color="primary">{{ LabelConstant.buttonLabelCreateNotificationUrl }}</vs-button>
                <vs-button @click.prevent="cancelCreate()" color="grey" type="border" class="ml-4 grey-btn">{{ LabelConstant.buttonLabelCancel }}</vs-button>
            </div>
        </form>
    </vs-popup>

    <!-- Add User Modal -->
    <vs-popup class="company-details-modal company-user" :title="(userId !=null && userId !='')?LabelConstant.popupTitleEditUser:LabelConstant.popupTitleCreateNewUser" :active.sync="addUserActive">
        <!-- Tab -->
        <vs-tabs direction="vertical" v-model="tabno" ref="tb">
            <vs-tab :label="LabelConstant.tabLabelUserInformation">
                <form autocomplete="off" data-vv-scope="userinfo">
                    <template>
                        <div class="vx-row">
                            <div class="vx-col w-full mb-6 md:w-1/2">
                                <vs-input class="w-full" :label="LabelConstant.inputLabelFirstName" v-model="firstName" v-validate="'required|max:300|min:1'" name="firstName" :disabled='vendastaIsPartner'/>
                                <span class="text-danger text-sm">{{ errors.first('userinfo.firstName') }}</span>
                            </div>
                            <div class="vx-col w-full mb-6 md:w-1/2">
                                <vs-input class="w-full" :label="LabelConstant.inputLabelLastName" v-model="lastName" v-validate="'required|max:300|min:1'" name="lastName" :disabled='vendastaIsPartner'/>
                                <span class="text-danger text-sm">{{ errors.first('userinfo.lastName') }}</span>
                            </div>
                        </div>
                        <div class="vx-row">
                            <div class="vx-col w-full mb-6 md:w-1/2">
                                <vs-input class="w-full" :label="LabelConstant.inputLabelEmail" v-model="email" v-validate="'required|email'" name="email" :disabled="disableEmail" />
                                <span class="text-danger text-sm">{{ errors.first('userinfo.email') }}</span>
                            </div>
                            <div class="vx-col w-full mb-6 md:w-1/2">
                                <vs-input class="w-full" :label="LabelConstant.inputLabelPhone" v-model="phone" v-validate="!vendastaIsPartner ? 'required|phone_number' : ''" name="phone" :disabled='vendastaIsPartner'/>
                                <span class="text-danger text-sm">{{ errors.first('userinfo.phone') }}</span>
                            </div>
                        </div>
                        <div class="vx-row" v-if="!vendastaIsPartner">
                            <div class="vx-col w-full mb-6">
                                <span class="vs-input--label">{{ LabelConstant.inputLabelRole }}</span>
                                <v-select v-model="role" :options="roleoptions" name="role" :reduce="roleoptions => roleoptions.Id" label="Role_Name" :clearable="false" :disabled='vendastaIsPartner'/>
                            </div>
                        </div>
                        <div class="vx-row">
                            <div class="vx-col w-full mb-6 md:w-1/2">
                                <vs-input type="password" class="w-full" :label="LabelConstant.inputLabelNewPassword" v-model="password" autocomplete="off" name="password" ref="passwordref" v-validate="'required'" :disabled='vendastaIsPartner'/>
                                <span class="text-danger text-sm">{{ errors.first('userinfo.password') }}</span>
                            </div>
                            <div class="vx-col w-full mb-6 md:w-1/2">
                                <vs-input type="password" class="w-full" :label="LabelConstant.inputLabelConfirmPassword" v-model="cpassword" name="cpassword" v-validate="'required|confirmed:passwordref'" :disabled='vendastaIsPartner'/>
                                <span class="text-danger text-sm">{{ errors.first('userinfo.cpassword') }}</span>
                            </div>
                        </div>
                        <div class="vx-row">
                            <div class="vx-col w-full mb-6">
                                <span class="vs-input--label">{{ LabelConstant.inputLabelTimeZone }}</span>
                                <v-select v-model="timeZone" :options="timeZoneoptions" name="timeZone" :reduce="timeZoneoption => timeZoneoption.Key" label="Value" :clearable="false"/>
                            </div>
                        </div>
                        <div class="vx-row mb-6">
                            <div class="vx-col w-full">
                                <vs-checkbox class="inline-flex" v-model="active" :disabled='vendastaIsPartner'>{{ LabelConstant.inputLableActive }}</vs-checkbox>
                            </div>
                        </div>
                    </template>
                </form>
            </vs-tab>
            <vs-tab :label="LabelConstant.tabLabelCRMIntegration" v-if="showCRMIntegration">
                <form autocomplete="off" data-vv-scope="crminfo">
                    <template>
                        <p class="mb-6">{{ LabelConstant.infoLabelUserCRM }}</p>
                        <vs-checkbox class="inline-flex mb-6" v-model="enableCrm" :disabled='vendastaIsPartner'>{{ LabelConstant.inputLabelEnableCRMIntegration }}</vs-checkbox>
                        <div class="vx-row">
                            <div class="vx-col w-full mb-6">
                                <span class="vs-input--label">{{ LabelConstant.inputLabelSelectCRMSystem }}</span>
                                <v-select :placeholder="LabelConstant.inputLabelSelectCRMSystem" v-model="crmSystem" name="crmSystem" :clearable="false" :options="crmsystemoptions" :reduce="crmsystemoptions => crmsystemoptions.value" :disabled="enableCrm == true?false:true" v-validate="(enableCrm == true)?'required':''" label="text" />
                                <span class="text-danger text-sm">{{ errors.first('crminfo.crmSystem') }}</span>
                            </div>
                        </div>
                        <div class="vx-row">
                            <div class="vx-col w-full mb-6">
                                <vs-input class="w-full" :label="LabelConstant.inputLabelAPIURL" v-model="crmurl" name="crmurl" v-validate="'required'" v-if="crmSystem == 'ConnectWise' || crmSystem == 'SalesNexus'"/>
                                <span class="text-danger text-sm">{{ errors.first('crminfo.crmurl') }}</span>
                            </div>
                            <div class="vx-col w-full mb-6">
                                <vs-input class="w-full" :label="LabelConstant.inputLabelSecurityToken" v-validate="(enableCrm == true)?'required':''" name="securitytoken" v-model="securitytoken" v-if="crmSystem !='Zoho'"/>
                                <span class="text-danger text-sm">{{ errors.first('crminfo.securitytoken') }}</span>
                            </div>
                        </div>
                        <div class="vx-row">
                            <div class="vx-col w-full mb-6" v-if="crmSystem == 'ConnectWise' || crmSystem == 'SalesNexus'|| crmSystem == 'SalesForce.com'">
                                <vs-input class="w-full" :label="LabelConstant.inputLabelUserName" v-model="username" name="username" v-validate="'required'"/>
                                <span class="text-danger text-sm">{{ errors.first('crminfo.username') }}</span>
                            </div>
                            <div class="vx-col w-full mb-6" v-show="crmSystem == 'ConnectWise' || crmSystem == 'SalesNexus'|| crmSystem == 'SalesForce.com'">
                                <vs-input type="password" class="w-full" :label="LabelConstant.inputLabelPassword" v-model="crmPassword" name="crmPassword" v-validate="'required'"/>
                                <span class="text-danger text-sm">{{ errors.first('crminfo.crmPassword') }}</span>
                            </div>
                        </div>
                        <div class="vx-row" v-if="crmSystem == 'SalesNexus'">
                            <div class="vx-col w-full mb-6 md:w-1/2">
                                <vs-checkbox class="inline-flex" v-model="CRM_Push_General_Companies">{{ LabelConstant.inputLabelPushWebsiteVisitsToCRMForUntaggedVisitors }}</vs-checkbox>
                            </div>
                            <div class="vx-col w-full mb-6 md:w-1/2">
                                <vs-checkbox class="inline-flex" v-model="CRM_Push_Tagged_Users">{{ LabelConstant.inputLabelPushWebsiteVisitsToCRMForTaggedVisitors }}</vs-checkbox>
                            </div>
                        </div>
                    </template>
                    <vs-table :data="defaultdata" max-items="8" class="mb-8" v-if="crmSystem == 'Zoho'">
                        <template slot="thead">
                            <vs-th>{{ LabelConstant.tableHeaderLabelSource }}</vs-th>
                            <vs-th>{{ LabelConstant.tableHeaderLabelStatus }}</vs-th>
                            <vs-th>{{ LabelConstant.tableHeaderLabelAction }}</vs-th>
                        </template>
                        <template slot-scope="{data}">
                            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                                <vs-td :data="tr.source">{{tr.source}}</vs-td>
                                <vs-td :data="tr.status">
                                    <vs-chip v-if="!zohoStatus" color="danger">{{ LabelConstant.statusConditionLabelNotConnected }}</vs-chip>
                                    <vs-chip v-else color="success">{{ LabelConstant.statusConditionLabelConnected }}</vs-chip>
                                </vs-td>
                                <vs-td :data="tr.action">
                                    <vs-button v-if="!zohoStatus" type="filled" class="small-btn bg-actionbutton" @click.prevent="linkZohoAccount()">{{ LabelConstant.buttonLabelLinkAccount }}</vs-button>
                                    <div v-else>
                                        <vs-button type="filled" class="small-btn bg-actionbutton" @click.prevent="getExternalAuthLink()">{{ LabelConstant.buttonLabelUpdate }}</vs-button>
                                        <vs-button color="danger" type="filled" class="small-btn" @click.prevent="deleteZohoAccount()">{{ LabelConstant.buttonLabelDelete }}</vs-button>
                                    </div>
                                </vs-td>
                            </vs-tr>
                        </template>
                    </vs-table>
                </form>
            </vs-tab>
            <vs-tab :label="LabelConstant.tabLabelOtherIntegration">
                <div class="vx-row">
                    <div :class="appRunningOnDev === 'true' ? $store.state.enableZapierIntegration ? 'vx-col w-full lg:w-1/2 mb-6' : 'vx-col w-full mb-6' : $store.state.enableZapierIntegration ? isConsumerPlan ? 'vx-col w-full mb-6' : 'vx-col w-full lg:w-1/2 mb-6' : 'vx-col w-full mb-6'">
                        <vx-card class="other-integration h-full">
                            <h3>{{ LabelConstant.headingLabelHTTPPostback }}</h3>
                            <p>{{ LabelConstant.infoLabelHTTPPostBack }}</p>
                            <vs-checkbox class="inline-flex" v-model="postback">{{ LabelConstant.inputLabelEnableHTTPPostback }}</vs-checkbox>
                            <form @submit.prevent="checkValidation()" autocomplete="off" data-vv-scope="checkpostback">
                                <template>
                                    <div class="vx-row">
                                        <div class="vx-col w-full">
                                            <vs-input class="w-full" :label="LabelConstant.inputLabelPostbackURL" name="postbackurl" v-model="postbackurl" v-validate="'required'"/>
                                            <span class="error-msg block">{{ errors.first('checkpostback.postbackurl') }}</span>
                                                                                                                            <span class="error-msg block" v-if="postBackURLError">{{postBackURLErrorMessage}}</span>
                                            <vs-button type="filled" class="mt-4 small-btn bg-actionbutton" :disabled="postback == true?false:true" button="submit">{{ LabelConstant.buttonLabelTestPostbackNow }}</vs-button>
                                        </div>
                                    </div>
                                </template>
                            </form>
                        </vx-card>
                    </div>
                    <div class="vx-col w-full lg:w-1/2 mb-6" v-if="appRunningOnDev === 'true' ? $store.state.enableZapierIntegration ? true : false : $store.state.enableZapierIntegration ? isConsumerPlan ? false : true : false">
                        <vx-card class="other-integration h-full">
                            <h3>{{ LabelConstant.headingLabelZapierIntegration }}</h3>
                            <p>
                                {{ LabelConstant.infoLabelEnableZapier }}
                                <a href="#">{{ LabelConstant.linkLabelZapier }}</a> {{ LabelConstant.infoLabelZapierInfo }}
                            </p>
                            <a href="#" v-if="!this.$store.state.isReseller" class="float-left w-full mb-8">{{ LabelConstant.linkLabelDownloadDocumentation }}</a>
                            <vs-checkbox class="inline-flex" v-model="enableZapier" name="enableZapier">{{ LabelConstant.inputLabelEnableZapierIntegration }}</vs-checkbox>
                            <div class="zapierkey" v-if="enableZapier && zapierKey">
                                <span>
                                    {{ LabelConstant.labelZapierAPIKey }}:
                                    <b>{{ zapierKey?zapierKey:'' }}</b>
                                </span>
                                <vs-button type="filled" class="mt-4 small-btn bg-actionbutton" @click="generateZapierKey()">{{ LabelConstant.buttonLabelGenerateNewZapierKey }}</vs-button>
                            </div>
                        </vx-card>
                    </div>
                </div>
            </vs-tab>
            <vs-tab :label="'Notification Rules'" v-if="isShowUserPecificNotificationRuleTab && mainMenuIsLeadHandlingNotificationRule">
                <div class="vx-row">
                    <div class="vx-col w-full mt-8">
                        <vx-card>
                            <div class="card-title">
                                <h2>Update My Notification Rules</h2>
                            </div>
                            <div class="notification-rule">
                                <div class="notification-details">
                                    <div class="vx-row">
                                        <div class="vx-col w-full flex flex-wrap justify-between">
                                            <h3 class="float-left w-auto mt-4">Please select your notification and reporting options.</h3>
                                            <div class="float-right mb-5 sm:w-auto w-full">
                                                <vs-button color="grey" type="border" class="ml-0 float-right sm:ml-4 sm:w-auto w-full mb-4 sm:mb-0 grey-btn" @click="getNotificationRule(userId)">{{ LabelConstant.buttonLabelCancelMyChange }}</vs-button>
                                                <vs-button type="filled" class="float-right sm:w-auto w-full" color="primary" @click="updateNotificationRule()">{{ LabelConstant.buttonLabelUpdateMyNotificationRules }}</vs-button>
                                            </div>
                                        </div>
                                        <div class="vx-col w-full lg:w-1/2 mb-8">
                                            <div class="notification-item">
                                                <vs-checkbox color="secondary" v-model="notificationEnabled">Send Instant Email Alerts</vs-checkbox>
                                                <ul>
                                                    <li>
                                                        <span>Send an email whenever a visitor views</span>
                                                        <v-select
                                                        v-model="notifyNumberPageVisits" :options="instantemailReportoptions" name="notifyNumberPageVisits" :reduce="instantemailReportoption => instantemailReportoption.value" label="label" :clearable="false" />
                                                        <span>pages of your website.</span>
                                                    </li>
                                                    <li>
                                                        <span>Do not send duplicate leads for</span>
                                                        <v-select
                                                        v-model="notificationDuplicateLeadDelay" :options="leadDayoptions" name="leadDayoptions" :reduce="leadDayoption => leadDayoption.value" label="label" :clearable="false" />
                                                        <span>days.</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="vx-col w-full lg:w-1/2 mb-8">
                                            <div class="notification-item">
                                                <vs-checkbox color="secondary"
                                                :disabled="isReportSettingsReadOnly"
                                                v-model="notifySendDailyReport">Send Daily Email Reports</vs-checkbox>
                                                <ul>
                                                    <li v-if="!vendastaIsPartner">
                                                        <span>Send daily reports showing the</span>
                                                        <v-select :disabled="isReportSettingsReadOnly" v-model="notifySendDailyReportNumber" :options="dailyemailReportoptions" name="notifySendDailyReportNumber" :reduce="dailyemailReportoption => dailyemailReportoption.value" label="label" :clearable="false" />
                                                        <span>most active visitors each day.</span>
                                                    </li>
                                                    <li>
                                                        <p>Daily reports are sent at approximately 3:00am Eastern Time and describe the previous day's activity.</p>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="vx-col w-full lg:w-1/2 mb-8">
                                            <div class="notification-item">
                                                <vs-checkbox color="secondary"
                                                :disabled="isReportSettingsReadOnly"
                                                v-model="notifySendWeeklyReport">Send Weekly Email Reports</vs-checkbox>
                                                <ul>
                                                    <li v-if="!vendastaIsPartner">
                                                        <span>Send weekly report showing the</span>
                                                        <v-select
                                                        :disabled="isReportSettingsReadOnly"
                                                        v-model="notifySendWeeklyReportNumber" :options="weeklyemailReportoptions" name="notifySendWeeklyReportNumber" :reduce="weeklyemailReportoption => weeklyemailReportoption.value" label="label" :clearable="false" />
                                                        <span>most active visitors each week.</span>
                                                    </li>
                                                    <li>
                                                        <p>Weekly reports are sent at approximately 3:00am Eastern Time every Monday and describe the previous week's activity.</p>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="vx-col w-full lg:w-1/2 mb-8" v-if="showNotificationRulesWatchListCard">
                                            <div class="notification-item">
                                                <vs-checkbox color="secondary" v-model="notificationEnabledWatchList">Send Watch List Email Alerts</vs-checkbox>
                                                <ul>
                                                    <li>
                                                        <p>Send an email alert each time a company on my watch list visits my website.</p>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="vx-col w-full lg:w-1/2 mb-8" v-if="!vendastaIsPartner">
                                            <div class="notification-item">
                                                <vs-checkbox color="secondary" v-model="notificationURLEnabled">Send URL Email Alerts</vs-checkbox>
                                                <ul>
                                                    <li>
                                                        <p>Watch these URLs and send an email if a {{  !notificationRuleIsConsumer?'company':'visitor'}} lands on them. Even if none of the other rules match.</p>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="notify-table" v-if="!vendastaIsPartner">
                                    <div class="justify-start flex w-full">
                                        <vs-button :title="LabelConstant.buttonLabelAddURLToWatch" @click="addpnewurlActive=true" type="filled" icon-pack="feather" icon="icon-plus" class="small-btn mb-4 bg-actionbutton">{{ LabelConstant.buttonLabelAddURLToWatch }}</vs-button>
                                    </div>
                                    <div class="common-table">
                                        <!--Table Select Row-->
                                        <div class="table-row-select">
                                            <!-- <vs-select label="Show Entries" v-model="notificationRulePerPage" class="show-select" autocomplete>
                                                <vs-select-item :key="index" :value="item.value" :text="item.text" v-model="notificationRulePerPage" v-for="(item,index) in recordList" />
                                            </vs-select> -->
                                            <div class="custom-v-select con-select show-select">
                                                <label class="vs-select--label pl-0">Show Entries</label>
                                                <v-select
                                                    class="show-select"
                                                    label="text"
                                                    :options="recordList"
                                                    :reduce="(option) => option.value"
                                                    :clearable="false"
                                                    v-model="notificationRulePerPage"
                                                />
                                            </div>
                                        </div>
                                        <vs-table :data="notifyUrl" search :max-items="notificationRulePerPage" pagination>
                                            <template slot="thead">
                                                <vs-th></vs-th>
                                                <vs-th>Notify on these URLs</vs-th>
                                            </template>
                                            <template slot-scope="{data}">
                                                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                                                    <vs-td width="50">
                                                        <vs-button @click="deleteNotifyUrl(tr)" :title="LabelConstant.buttonLabelDelete" color="danger" type="filled" icon-pack="feather" icon="icon-trash-2" class="mr-0"></vs-button>
                                                    </vs-td>
                                                    <vs-td :data="tr.Site_URL">{{tr.Site_URL}}</vs-td>
                                                </vs-tr>
                                            </template>
                                        </vs-table>
                                    </div>
                                </div>
                                <div class="justify-end flex w-full flex-wrap" v-if="!vendastaIsPartner">
                                    <vs-button type="filled" @click="updateNotificationRule()" class="sm:w-auto w-full mb-4 sm:mb-0" color="primary">{{ LabelConstant.buttonLabelUpdateMyNotificationRules }}</vs-button>
                                    <vs-button color="grey" type="border" class="ml-0 sm:ml-4 sm:w-auto w-full grey-btn" @click="getNotificationRule(userId)">{{ LabelConstant.buttonLabelCancelMyChange }}</vs-button>
                                </div>
                            </div>
                        </vx-card>
                    </div>
                </div>
            </vs-tab>
            <vs-tab :label="'Territory Rules'" v-if="isShowUserPecificTerritoryRuleTab && mainMenuIsLeadHandlingTerritoryRule">
                <div class="vx-row">
                    <div class="vx-col w-full mt-8">
                        <vx-card>
                            <div class="card-title">
                                <h2>Update My Territory Rules</h2>
                            </div>
                            <!-- Tab -->
                            <vs-tabs class="territory-tab" color="rgb(236, 185, 64)">
                                <vs-tab label="US Territory Assignments" color="rgb(236, 185, 64)">
                                    <div class="tab-text">
                                        <div class="justify-end flex-wrap flex w-full">
                                        <vs-button
                                            :title="LabelConstant.buttonLabelSelectAllStates"
                                            color="primary"
                                            type="border"
                                            class="small-btn mb-4"
                                            @click.prevent="selectAllState()"
                                        >{{ LabelConstant.buttonLabelSelectAllStates }}</vs-button>
                                        <vs-button
                                            :title="LabelConstant.buttonLabelUnselectAllStates"
                                            color="primary"
                                            type="border"
                                            class="small-btn mb-4 ml-4"
                                            @click.prevent="unselectAllState()"
                                        >{{ LabelConstant.buttonLabelUnselectAllStates }}</vs-button>
                                        <vs-button
                                            color="primary"
                                            type="filled"
                                            class="small-btn mb-4 ml-4"
                                            @click="updateUSTerritoryForUser()"
                                        >{{ LabelConstant.buttonLabelUpdateYourUsTerritory }}</vs-button>
                                        <vs-button
                                            color="grey"
                                            type="border"
                                            class="small-btn mb-4 ml-4 grey-btn"
                                            @click="cancelStateChanges()"
                                        >{{ LabelConstant.buttonLabelCancelMyChange }}</vs-button>
                                        </div>

                                        <div class="notifications-states">
                                        <h3>Restrict your lead notifications to the following states.</h3>
                                        <ul>
                                            <li v-for="(state,index) in statesAvailable" :key="index">
                                            <vs-checkbox v-model="states" :vs-value="state.code">{{state.name}}</vs-checkbox>
                                            </li>
                                        </ul>
                                        </div>
                                        <div class="justify-end flex flex-wrap w-full">
                                        <vs-button
                                            :title="LabelConstant.buttonLabelSelectAllStates"
                                            color="primary"
                                            type="border"
                                            class="ml-4 mt-4"
                                            button="button"
                                            @click.prevent="selectAllState()"
                                        >{{ LabelConstant.buttonLabelSelectAllStates }}</vs-button>
                                        <vs-button
                                            :title="LabelConstant.buttonLabelUnselectAllStates"
                                            color="primary"
                                            type="border"
                                            class="ml-4 mt-4"
                                            button="button"
                                            @click="unselectAllState()"
                                        >{{ LabelConstant.buttonLabelUnselectAllStates }}</vs-button>
                                        <vs-button
                                            color="primary"
                                            type="filled"
                                            class="ml-4 mt-4"
                                            @click="updateUSTerritoryForUser()"
                                        >{{ LabelConstant.buttonLabelUpdateYourUsTerritory }}</vs-button>
                                        <vs-button
                                            color="grey"
                                            type="border"
                                            class="ml-4 mt-4 grey-btn"
                                            @click="cancelStateChanges()"
                                        >{{ LabelConstant.buttonLabelCancelMyChange }}</vs-button>
                                        </div>
                                    </div>
                                </vs-tab>
                                <vs-tab label="International Territory Assignments" color="rgb(236, 185, 64)" v-if="showInternationlLink">
                                    <div class="tab-text">
                                        <div class="justify-end flex-wrap  flex w-full">
                                        <vs-button
                                            :title="LabelConstant.buttonLabelSelectAllCountries"
                                            color="primary"
                                            type="border"
                                            class="small-btn mb-4"
                                            @click="selectAllCountries()"
                                        >{{ LabelConstant.buttonLabelSelectAllCountries }}</vs-button>
                                        <vs-button
                                            :title="LabelConstant.buttonLabelUnselectAllCountries"
                                            color="primary"
                                            type="border"
                                            class="small-btn mb-4 ml-4"
                                            @click="unselectAllCountries()"
                                        >{{ LabelConstant.buttonLabelUnselectAllCountries }}</vs-button>
                                        <vs-button
                                            color="primary"
                                            class="small-btn mb-4 ml-4"
                                            type="filled"
                                            @click="updateCountryTerritoryForUser()"
                                        >{{ LabelConstant.buttonLabelUpdateYourInternationalTerritory }}</vs-button>
                                        <vs-button
                                            color="grey"
                                            type="border"
                                            class="small-btn mb-4 ml-4 grey-btn"
                                            @click="cancelCountriesChanges()"
                                        >{{ LabelConstant.buttonLabelCancelMyChange }}</vs-button>
                                        </div>
                                        <div class="notifications-states">
                                        <h3>Restrict your lead notifications to the following countries.</h3>
                                        <ul>
                                            <li v-for="(country,index) in countriesAvailable" :key="index">
                                            <vs-checkbox v-model="countries" :vs-value="country.code">{{country.name}}</vs-checkbox>
                                            </li>
                                        </ul>
                                        </div>
                                        <div class="justify-end flex-wrap mt-4 float-left flex w-full">
                                        <vs-button
                                            :title="LabelConstant.buttonLabelSelectAllCountries"
                                            color="primary"
                                            type="border"
                                            class="ml-4 mt-2"
                                            @click="selectAllCountries()"
                                        >{{ LabelConstant.buttonLabelSelectAllCountries }}</vs-button>
                                        <vs-button
                                            :title="LabelConstant.buttonLabelUnselectAllCountries"
                                            color="primary"
                                            type="border"
                                            class="ml-4 mt-2"
                                            @click="unselectAllCountries()"
                                        >{{ LabelConstant.buttonLabelUnselectAllCountries }}</vs-button>
                                        <vs-button
                                            color="primary"
                                            type="filled"
                                            class="ml-4 mt-2"
                                            @click="updateCountryTerritoryForUser()"
                                        >{{ LabelConstant.buttonLabelUpdateYourInternationalTerritory }}</vs-button>
                                        <vs-button
                                            color="grey"
                                            type="border"
                                            class="ml-4 mt-2 grey-btn"
                                            @click="cancelCountriesChanges()"
                                        >{{ LabelConstant.buttonLabelCancelMyChange }}</vs-button>
                                        </div>
                                    </div>
                                </vs-tab>
                            </vs-tabs>
                        </vx-card>
                    </div>
                </div>
            </vs-tab>
        </vs-tabs>
        <div class="flex justify-end modal-action-buttons w-full float-left flex-wrap mb-8">
            <vs-button type="filled" class="w-full sm:w-auto mb-2 sm:mb-0 bg-actionbutton" @click="makeDefaultUser()" v-if="(defaultUser ==false) && userId !=null && userId !='' && !vendastaIsPartner">{{ LabelConstant.buttonLabelMakePrimaryUser }}</vs-button>
            <vs-button type="filled" class="ml-0 sm:ml-4 mb-2 sm:mb-0 w-full sm:w-auto" color="primary" @click="isCreateUpdateUser = true,validateUser()">{{ (userId !=null && userId !='')?LabelConstant.buttonLabelUpdateUser :LabelConstant.buttonLabelCreateUser }}</vs-button>
            <vs-button color="grey" type="border" class="ml-0 sm:ml-4 w-full sm:w-auto grey-btn" @click="closePopupUser()">{{ LabelConstant.buttonLabelClose }}</vs-button>
        </div>
    </vs-popup>

    <!-- Delete User Modal -->
    <vs-popup class="delete-popup" :active.sync="deletepopupActive">
        <template>
            <div class="vx-row mb-3">
                <div class="vx-col w-full">
                    <div class="popup-icon">
                        <feather-icon icon="Trash2Icon"></feather-icon>
                    </div>
                    <h4>{{ LabelConstant.popupHeadingLabelDeleteUser }}</h4>
                    <span class="deleted-no">{{ email?email:''}}</span>
                </div>
            </div>
        </template>
        <div class="float-right mt-6 mb-8">
            <vs-button color="danger" type="filled" @click="deleteUserConfirm()">{{ LabelConstant.buttonLabelDelete }}</vs-button>
            <vs-button color="grey" type="border" class="ml-4 grey-btn" @click="deletepopupActive=false">{{ LabelConstant.buttonLabelCancel }}</vs-button>
        </div>
    </vs-popup>

    <vs-popup class="transaction-popup" :active.sync="purchaseAdditionalPopup">
        <div class="transaction-details">
            <div class="popup-icon">
                <img src="../../assets/images/warning.svg" />
            </div>
            <h2>{{ LabelConstant.headingLabelPurchaseAdditionalUsers }}</h2>
            <p>{{ LabelConstant.infoLabelPurchaseAdditionalUser }}</p>
            <vs-button @click="purchaseAdditionalPopup=false" color="grey" type="border" class="grey-btn">{{ LabelConstant.buttonLabelOk }}</vs-button>
        </div>
    </vs-popup>

    <!-- Help popup -->
    <help-modal :popupActive="popupActive" @close="closepopup"></help-modal>
</div>
</template>

<script>
import VueJwtDecode from "vue-jwt-decode";
import VxCard from "@/components/vx-card/VxCard";
import HelpModal from "../../components/modal/HelpModal";
import {
    Validator
} from "vee-validate";
Validator.extend("phone_number", {
    validate: (value) => {
        // var strongRegex = new RegExp("^[0-9-]+$");
        // var strongRegex = new RegExp("^[+]{1}(?:[0-9\\-\\(\\)\\/\\.]\\s?){6,15}[0-9]{1}$");
        const strongRegex = new RegExp('^(?:.)(?:[0-9\\-\\(\\)\\/\\.]\\s?){6,15}[0-9]{1}$')
        return strongRegex.test(value);
    },
});
/* Filter */
import vSelect from "vue-select";
//import Cookie from "js-cookie";
const dict = {
    custom: {
        postbackurl: {
            required: "Please enter a valid URL",
        },
        phone: {
            required: "Please enter valid phone number",
            phone_number: "Please enter valid phone number",
        },
        cpassword: {
            confirmed: "Password and confrim password does not match",
            required: "Please enter confirm password",
        },
        siteUrl: {
            required: "A valid URL is required.",
            url: "Please enter a valid URL."
        }
    },
};
Validator.localize("en", dict);
export default {
    components: {
        VxCard,
        vSelect,
        HelpModal,
        VueJwtDecode,
    },
    data() {
        return {
            appRunningOnDev:process.env.VUE_APP_RUNNING_ON_DEV?process.env.VUE_APP_RUNNING_ON_DEV:'',
            isConsumerPlan:false,
            isReportSettingsReadOnly: false,
            mainMenuIsLeadHandlingNotificationRule: false,
            mainMenuIsLeadHandlingTerritoryRule: false,
            disableEmail: false,
            tabno: 0,
            userId: null,
            firstName: null,
            lastName: null,
            phone: null,
            password: null,
            cpassword: null,
            crmPassword: null,
            active: true,
            enableCrm: false,
            crmurl: null,
            securitytoken: null,
            username: null,
            CRM_Push_Tagged_Users: false,
            CRM_Push_General_Companies: false,
            email: null,
            postbackurl: null,
            postback: false,
            enableZapier: false,
            zapierKey: null,
            addUserActive: false,
            popupActive: false,
            deletepopupActive: false,
            defaultUser: false,
            isCreateUpdateUser:false,
            userinfo: [],
            userCount: {},
            perPage: 5,
            recordList: [{
                    text: "5",
                    value: 5,
                },
                {
                    text: "10",
                    value: 10,
                },
                {
                    text: "25",
                    value: 25,
                },
                {
                    text: "50",
                    value: 50,
                },
                {
                    text: "100",
                    value: 100,
                },
            ],
            role: "",
            roleoptions: [],
            userV: false,
            crmV: false,
            postV: false,
            timeZone: "Eastern Standard Time",
            timeZoneoptions: [],
            crmSystem: "",
            crmsystemoptions: [{
                    value: "ConnectWise",
                    text: "ConnectWise",
                },
                {
                    value: "LeadMaster",
                    text: "LeadMaster",
                },
                {
                    value: "SalesForce.com",
                    text: "SalesForce.com",
                },
                {
                    value: "SalesNexus",
                    text: "SalesNexus",
                },
            ],
            zohoStatus: false,
            /* Table */
            defaultdata: [{
                source: "Zoho",
            }, ],

            purchaseAdditionalPopup: false,
            isReseller: null,
            isResellerClient: null,
            isFreeTrial: null,
            postBackURLError:false,
            postBackURLErrorMessage:null,
            showCRMIntegration:true,

            vendastaIsPartner: false,

            //User Specific Notification Rule and Territory Rule
            isShowUserPecificNotificationRuleTab: false,
            isShowUserPecificTerritoryRuleTab: false,
            //Territory Tab
            states: [],
            countries: [],
            statesAvailable: [
                { code: "AL", name: "Alabama" },
                { code: "AK", name: "Alaska" },
                //"Acode:"S"", name: "American Samoa",
                { code: "AZ", name: "Arizona" },
                { code: "AR", name: "Arkansas" },
                { code: "CA", name: "California" },
                { code: "CO", name: "Colorado" },
                { code: "CT", name: "Connecticut" },
                { code: "DE", name: "Delaware" },
                { code: "DC", name: "District Of Columbia" },
                //"Fcode:"M"", name: "Federated States Of Micronesia",
                { code: "FL", name: "Florida" },
                { code: "GA", name: "Georgia" },
                //"Gcode:"U"", name: "Guam",
                { code: "HI", name: "Hawaii" },
                { code: "ID", name: "Idaho" },
                { code: "IL", name: "Illinois" },
                { code: "IN", name: "Indiana" },
                { code: "IA", name: "Iowa" },
                { code: "KS", name: "Kansas" },
                { code: "KY", name: "Kentucky" },
                { code: "LA", name: "Louisiana" },
                { code: "ME", name: "Maine" },
                //"Mcode:"H"", name: "Marshall Islands",
                { code: "MD", name: "Maryland" },
                { code: "MA", name: "Massachusetts" },
                { code: "MI", name: "Michigan" },
                { code: "MN", name: "Minnesota" },
                { code: "MS", name: "Mississippi" },
                { code: "MO", name: "Missouri" },
                { code: "MT", name: "Montana" },
                { code: "NE", name: "Nebraska" },
                { code: "NV", name: "Nevada" },
                { code: "NH", name: "New Hampshire" },
                { code: "NJ", name: "New Jersey" },
                { code: "NM", name: "New Mexico" },
                { code: "NY", name: "New York" },
                { code: "NC", name: "North Carolina" },
                { code: "ND", name: "North Dakota" },
                { code: "OH", name: "Ohio" },
                { code: "OK", name: "Oklahoma" },
                { code: "OR", name: "Oregon" },
                //"Pcode:"W"", name: "Palau",
                { code: "PA", name: "Pennsylvania" },
                // "Pcode:"R"", name: "Puerto Rico",
                { code: "RI", name: "Rhode Island" },
                { code: "SC", name: "South Carolina" },
                { code: "SD", name: "South Dakota" },
                { code: "TN", name: "Tennessee" },
                { code: "TX", name: "Texas" },
                { code: "UT", name: "Utah" },
                { code: "VT", name: "Vermont" },
                { code: "VI", name: "Virgin Islands" },
                { code: "VA", name: "Virginia" },
                { code: "WA", name: "Washington" },
                { code: "WV", name: "West Virginia" },
                { code: "WI", name: "Wisconsin" },
                { code: "WY", name: "Wyoming" }
            ],
            countriesAvailable: [
                { name: "Afghanistan", code: "AF" },
                { name: "Åland Islands", code: "AX" },
                { name: "Albania", code: "AL" },
                { name: "Algeria", code: "DZ" },
                { name: "American Samoa", code: "AS" },
                { name: "AndorrA", code: "AD" },
                { name: "Angola", code: "AO" },
                { name: "Anguilla", code: "AI" },
                { name: "Antarctica", code: "AQ" },
                { name: "Antigua and Barbuda", code: "AG" },
                { name: "Argentina", code: "AR" },
                { name: "Armenia", code: "AM" },
                { name: "Aruba", code: "AW" },
                { name: "Asia/Pacific Region", code: "AP" },
                { name: "Australia", code: "AU" },

                { name: "Austria", code: "AT" },
                { name: "Azerbaijan", code: "AZ" },
                { name: "Bahamas", code: "BS" },
                { name: "Bahrain", code: "BH" },
                { name: "Bangladesh", code: "BD" },
                { name: "Barbados", code: "BB" },
                { name: "Belarus", code: "BY" },
                { name: "Belgium", code: "BE" },
                { name: "Belize", code: "BZ" },
                { name: "Benin", code: "BJ" },
                { name: "Bermuda", code: "BM" },
                { name: "Bhutan", code: "BT" },
                { name: "Bolivia", code: "BO" },
                { name: "Bosnia and Herzegovina", code: "BA" },
                { name: "Botswana", code: "BW" },
                { name: "Bouvet Island", code: "BV" },
                { name: "Brazil", code: "BR" },
                { name: "British Indian Ocean Territory", code: "IO" },
                { name: "Brunei Darussalam", code: "BN" },
                { name: "Bulgaria", code: "BG" },
                { name: "Burkina Faso", code: "BF" },
                { name: "Burundi", code: "BI" },
                { name: "Cambodia", code: "KH" },
                { name: "Cameroon", code: "CM" },
                { name: "Canada", code: "CA" },
                { name: "Cape Verde", code: "CV" },
                { name: "Cayman Islands", code: "KY" },
                { name: "Central African Republic", code: "CF" },
                { name: "Chad", code: "TD" },
                { name: "Chile", code: "CL" },
                { name: "China", code: "CN" },
                { name: "Christmas Island", code: "CX" },
                { name: "Cocos (Keeling) Islands", code: "CC" },
                { name: "Colombia", code: "CO" },
                { name: "Comoros", code: "KM" },
                { name: "Congo", code: "CG" },
                { name: "Congo, The Democratic Republic of the", code: "CD" },
                { name: "Cook Islands", code: "CK" },
                { name: "Costa Rica", code: "CR" },
                { name: "Cote D'Ivoire", code: "CI" },
                { name: "Croatia", code: "HR" },
                { name: "Cuba", code: "CU" },
                { name: "Cyprus", code: "CY" },
                { name: "Czech Republic", code: "CZ" },
                { name: "Denmark", code: "DK" },
                { name: "Djibouti", code: "DJ" },
                { name: "Dominica", code: "DM" },
                { name: "Dominican Republic", code: "DO" },
                { name: "Ecuador", code: "EC" },
                { name: "Egypt", code: "EG" },
                { name: "El Salvador", code: "SV" },
                { name: "Equatorial Guinea", code: "GQ" },
                { name: "Eritrea", code: "ER" },
                { name: "Estonia", code: "EE" },
                { name: "Ethiopia", code: "ET" },
                { name: "Europe", code: "EU" },
                { name: "Falkland Islands (Malvinas)", code: "FK" },
                { name: "Faroe Islands", code: "FO" },
                { name: "Fiji", code: "FJ" },
                { name: "Finland", code: "FI" },
                { name: "France", code: "FR" },
                { name: "French Guiana", code: "GF" },
                { name: "French Polynesia", code: "PF" },
                { name: "French Southern Territories", code: "TF" },
                { name: "Gabon", code: "GA" },
                { name: "Gambia", code: "GM" },
                { name: "Georgia", code: "GE" },
                { name: "Germany", code: "DE" },
                { name: "Ghana", code: "GH" },
                { name: "Gibraltar", code: "GI" },
                { name: "Greece", code: "GR" },
                { name: "Greenland", code: "GL" },
                { name: "Grenada", code: "GD" },
                { name: "Guadeloupe", code: "GP" },
                { name: "Guam", code: "GU" },
                { name: "Guatemala", code: "GT" },
                { name: "Guernsey", code: "GG" },
                { name: "Guinea", code: "GN" },
                { name: "Guinea-Bissau", code: "GW" },
                { name: "Guyana", code: "GY" },
                { name: "Haiti", code: "HT" },
                { name: "Heard Island and Mcdonald Islands", code: "HM" },
                { name: "Holy See (Vatican City State)", code: "VA" },
                { name: "Honduras", code: "HN" },
                { name: "Hong Kong", code: "HK" },
                { name: "Hungary", code: "HU" },
                { name: "Iceland", code: "IS" },
                { name: "India", code: "IN" },
                { name: "Indonesia", code: "ID" },
                { name: "Iran, Islamic Republic Of", code: "IR" },
                { name: "Iraq", code: "IQ" },
                { name: "Ireland", code: "IE" },
                { name: "Isle of Man", code: "IM" },
                { name: "Israel", code: "IL" },
                { name: "Italy", code: "IT" },
                { name: "Jamaica", code: "JM" },
                { name: "Japan", code: "JP" },
                { name: "Jersey", code: "JE" },
                { name: "Jordan", code: "JO" },
                { name: "Kazakhstan", code: "KZ" },
                { name: "Kenya", code: "KE" },
                { name: "Kiribati", code: "KI" },
                { name: "Korea, Democratic People'S Republic of", code: "KP" },
                { name: "Korea, Republic of", code: "KR" },
                { name: "Kuwait", code: "KW" },
                { name: "Kyrgyzstan", code: "KG" },
                { name: "Lao People'S Democratic Republic", code: "LA" },
                { name: "Latvia", code: "LV" },
                { name: "Lebanon", code: "LB" },
                { name: "Lesotho", code: "LS" },
                { name: "Liberia", code: "LR" },
                { name: "Libyan Arab Jamahiriya", code: "LY" },
                { name: "Liechtenstein", code: "LI" },
                { name: "Lithuania", code: "LT" },
                { name: "Luxembourg", code: "LU" },
                { name: "Macao", code: "MO" },
                { name: "Macedonia, The Former Yugoslav Republic of", code: "MK" },
                { name: "Madagascar", code: "MG" },
                { name: "Malawi", code: "MW" },
                { name: "Malaysia", code: "MY" },
                { name: "Maldives", code: "MV" },
                { name: "Mali", code: "ML" },
                { name: "Malta", code: "MT" },
                { name: "Marshall Islands", code: "MH" },
                { name: "Martinique", code: "MQ" },
                { name: "Mauritania", code: "MR" },
                { name: "Mauritius", code: "MU" },
                { name: "Mayotte", code: "YT" },
                { name: "Mexico", code: "MX" },
                { name: "Micronesia, Federated States of", code: "FM" },
                { name: "Moldova, Republic of", code: "MD" },
                { name: "Monaco", code: "MC" },
                { name: "Mongolia", code: "MN" },
                { name: "Montenegro", code: "ME" },
                { name: "Montserrat", code: "MS" },
                { name: "Morocco", code: "MA" },
                { name: "Mozambique", code: "MZ" },
                { name: "Myanmar", code: "MM" },
                { name: "Namibia", code: "NA" },
                { name: "Nauru", code: "NR" },
                { name: "Nepal", code: "NP" },
                { name: "Netherlands", code: "NL" },
                //{name: 'Netherlands Antilles', code: 'AN'},
                { name: "New Caledonia", code: "NC" },
                { name: "New Zealand", code: "NZ" },
                { name: "Nicaragua", code: "NI" },
                { name: "Niger", code: "NE" },
                { name: "Nigeria", code: "NG" },
                { name: "Niue", code: "NU" },
                { name: "Norfolk Island", code: "NF" },
                { name: "Northern Mariana Islands", code: "MP" },
                { name: "Norway", code: "NO" },
                { name: "Oman", code: "OM" },
                { name: "Pakistan", code: "PK" },
                { name: "Palau", code: "PW" },
                { name: "Palestinian Territory, Occupied", code: "PS" },
                { name: "Panama", code: "PA" },
                { name: "Papua New Guinea", code: "PG" },
                { name: "Paraguay", code: "PY" },
                { name: "Peru", code: "PE" },
                { name: "Philippines", code: "PH" },
                { name: "Pitcairn", code: "PN" },
                { name: "Poland", code: "PL" },
                { name: "Portugal", code: "PT" },
                { name: "Puerto Rico", code: "PR" },
                { name: "Qatar", code: "QA" },
                { name: "Reunion", code: "RE" },
                { name: "Romania", code: "RO" },
                { name: "Russian Federation", code: "RU" },
                { name: "RWANDA", code: "RW" },
                { name: "Saint Bartelemey", code: "BL" },

                { name: "Saint Helena", code: "SH" },
                { name: "Saint Kitts and Nevis", code: "KN" },
                { name: "Saint Lucia", code: "LC" },
                { name: "Saint Martin", code: "MF" },

                { name: "Saint Pierre and Miquelon", code: "PM" },
                { name: "Saint Vincent and the Grenadines", code: "VC" },
                { name: "Samoa", code: "WS" },
                { name: "San Marino", code: "SM" },
                { name: "Sao Tome and Principe", code: "ST" },
                { name: "Saudi Arabia", code: "SA" },
                { name: "Senegal", code: "SN" },
                { name: "Serbia", code: "RS" },

                //{name: 'Serbia and Montenegro', code: 'CS'},
                { name: "Seychelles", code: "SC" },
                { name: "Sierra Leone", code: "SL" },
                { name: "Singapore", code: "SG" },
                { name: "Sint Maarten", code: "SX" },

                { name: "Slovakia", code: "SK" },
                { name: "Slovenia", code: "SI" },
                { name: "Solomon Islands", code: "SB" },
                { name: "Somalia", code: "SO" },
                { name: "South Africa", code: "ZA" },
                { name: "South Georgia and the South Sandwich Islands", code: "GS" },
                { name: "Spain", code: "ES" },
                { name: "Sri Lanka", code: "LK" },
                { name: "Sudan", code: "SD" },
                { name: "Suriname", code: "SR" },
                { name: "Svalbard and Jan Mayen", code: "SJ" },
                { name: "Swaziland", code: "SZ" },
                { name: "Sweden", code: "SE" },
                { name: "Switzerland", code: "CH" },
                { name: "Syrian Arab Republic", code: "SY" },
                { name: "Taiwan, Province of China", code: "TW" },
                { name: "Tajikistan", code: "TJ" },
                { name: "Tanzania, United Republic of", code: "TZ" },
                { name: "Thailand", code: "TH" },
                { name: "Timor-Leste", code: "TL" },
                { name: "Togo", code: "TG" },
                { name: "Tokelau", code: "TK" },
                { name: "Tonga", code: "TO" },
                { name: "Trinidad and Tobago", code: "TT" },
                { name: "Tunisia", code: "TN" },
                { name: "Turkey", code: "TR" },
                { name: "Turkmenistan", code: "TM" },
                { name: "Turks and Caicos Islands", code: "TC" },
                { name: "Tuvalu", code: "TV" },
                { name: "Uganda", code: "UG" },
                { name: "Ukraine", code: "UA" },
                { name: "United Arab Emirates", code: "AE" },
                { name: "United Kingdom", code: "GB" },
                { name: "United States", code: "US" },
                { name: "United States Minor Outlying Islands", code: "UM" },
                { name: "Uruguay", code: "UY" },
                { name: "Uzbekistan", code: "UZ" },
                { name: "Vanuatu", code: "VU" },
                { name: "Venezuela", code: "VE" },
                { name: "Viet Nam", code: "VN" },
                { name: "Virgin Islands, British", code: "VG" },
                { name: "Virgin Islands, U.S.", code: "VI" },
                { name: "Wallis and Futuna", code: "WF" },
                { name: "Western Sahara", code: "EH" },
                { name: "Yemen", code: "YE" },
                { name: "Zambia", code: "ZM" },
                { name: "Zimbabwe", code: "ZW" }
            ],
            //Notification Rule Tab
            deleteUrl: null,
            deleteNotificationPopupActive: false,
            notificationEnabled: null,
            notifySendDailyReport: null,
            notifySendWeeklyReport: null,
            notificationEnabledWatchList: null,
            notificationURLEnabled: null,

            notifyNumberPageVisits: null,
            notificationDuplicateLeadDelay: null,
            notifySendDailyReportNumber: null,
            notifySendWeeklyReportNumber: null,

            addpnewurlActive: false,
            siteUrl: null,
            instantEmail: false,
            dailyEmail: false,
            weeklyEmail: false,
            watchEmail: false,
            sendurlEmail: false,
            /* Select */
            instantemailReportoptions: [{
                    label: "1",
                    value: 1
                },
                {
                    label: "2",
                    value: 2
                },
                {
                    label: "3",
                    value: 3
                },
                {
                    label: "4",
                    value: 4
                },
                {
                    label: "5",
                    value: 5
                },
                {
                    label: "6",
                    value: 6
                },
                {
                    label: "7",
                    value: 7
                },
                {
                    label: "8",
                    value: 8
                },
                {
                    label: "9",
                    value: 9
                },
                {
                    label: "10",
                    value: 10
                },
                {
                    label: "11",
                    value: 11
                },
                {
                    label: "12",
                    value: 12
                },
                {
                    label: "13",
                    value: 13
                },
                {
                    label: "14",
                    value: 14
                },
                {
                    label: "15",
                    value: 15
                },
                {
                    label: "16",
                    value: 16
                },
                {
                    label: "17",
                    value: 17
                },
                {
                    label: "18",
                    value: 18
                },
                {
                    label: "19",
                    value: 19
                },
                {
                    label: "20",
                    value: 20
                },
                {
                    label: "21",
                    value: 21
                },
                {
                    label: "22",
                    value: 22
                },
                {
                    label: "23",
                    value: 23
                },
                {
                    label: "24",
                    value: 24
                },
                {
                    label: "25",
                    value: 25
                },
                {
                    label: "26",
                    value: 26
                },
                {
                    label: "27",
                    value: 27
                },
                {
                    label: "28",
                    value: 28
                },
                {
                    label: "29",
                    value: 29
                },
                {
                    label: "30",
                    value: 30
                },
                {
                    label: "31",
                    value: 31
                },
                {
                    label: "32",
                    value: 32
                },
                {
                    label: "33",
                    value: 33
                },
                {
                    label: "34",
                    value: 34
                },
                {
                    label: "35",
                    value: 35
                },
                {
                    label: "36",
                    value: 36
                },
                {
                    label: "37",
                    value: 37
                },
                {
                    label: "38",
                    value: 38
                },
                {
                    label: "39",
                    value: 39
                },
                {
                    label: "40",
                    value: 40
                },
                {
                    label: "41",
                    value: 41
                },
                {
                    label: "42",
                    value: 42
                },
                {
                    label: "43",
                    value: 43
                },
                {
                    label: "44",
                    value: 44
                },
                {
                    label: "45",
                    value: 45
                },
                {
                    label: "46",
                    value: 46
                },
                {
                    label: "47",
                    value: 47
                },
                {
                    label: "48",
                    value: 48
                },
                {
                    label: "49",
                    value: 49
                },
                {
                    label: "50",
                    value: 50
                }
            ],
            emailReport: [],
            emailReportoptions: [{
                    label: "0",
                    value: 0
                },
                {
                    label: "1",
                    value: 1
                },
                {
                    label: "2",
                    value: 2
                },
                {
                    label: "3",
                    value: 3
                },
                {
                    label: "4",
                    value: 4
                },
                {
                    label: "5",
                    value: 5
                },
                {
                    label: "6",
                    value: 6
                },
                {
                    label: "7",
                    value: 7
                },
                {
                    label: "8",
                    value: 8
                },
                {
                    label: "9",
                    value: 9
                },
                {
                    label: "10",
                    value: 10
                },
                {
                    label: "11",
                    value: 11
                },
                {
                    label: "12",
                    value: 12
                },
                {
                    label: "13",
                    value: 13
                },
                {
                    label: "14",
                    value: 14
                },
                {
                    label: "15",
                    value: 15
                },
                {
                    label: "16",
                    value: 16
                },
                {
                    label: "17",
                    value: 17
                },
                {
                    label: "18",
                    value: 18
                },
                {
                    label: "19",
                    value: 19
                },
                {
                    label: "20",
                    value: 20
                },
                {
                    label: "21",
                    value: 21
                },
                {
                    label: "22",
                    value: 22
                },
                {
                    label: "23",
                    value: 23
                },
                {
                    label: "24",
                    value: 24
                },
                {
                    label: "25",
                    value: 25
                },
                {
                    label: "26",
                    value: 26
                },
                {
                    label: "27",
                    value: 27
                },
                {
                    label: "28",
                    value: 28
                },
                {
                    label: "29",
                    value: 29
                },
                {
                    label: "30",
                    value: 30
                },
                {
                    label: "31",
                    value: 31
                },
                {
                    label: "32",
                    value: 32
                },
                {
                    label: "33",
                    value: 33
                },
                {
                    label: "34",
                    value: 34
                },
                {
                    label: "35",
                    value: 35
                },
                {
                    label: "36",
                    value: 36
                },
                {
                    label: "37",
                    value: 37
                },
                {
                    label: "38",
                    value: 38
                },
                {
                    label: "39",
                    value: 39
                },
                {
                    label: "40",
                    value: 40
                },
                {
                    label: "41",
                    value: 41
                },
                {
                    label: "42",
                    value: 42
                },
                {
                    label: "43",
                    value: 43
                },
                {
                    label: "44",
                    value: 44
                },
                {
                    label: "45",
                    value: 45
                },
                {
                    label: "46",
                    value: 46
                },
                {
                    label: "47",
                    value: 47
                },
                {
                    label: "48",
                    value: 48
                },
                {
                    label: "49",
                    value: 49
                },
                {
                    label: "50",
                    value: 50
                }
            ],
            leadDay: [],
            leadDayoptions: [{
                    label: "1",
                    value: 1
                },
                {
                    label: "2",
                    value: 2
                },
                {
                    label: "3",
                    value: 3
                },
                {
                    label: "4",
                    value: 4
                },
                {
                    label: "5",
                    value: 5
                },
                {
                    label: "6",
                    value: 6
                },
                {
                    label: "7",
                    value: 7
                },
                {
                    label: "8",
                    value: 8
                },
                {
                    label: "9",
                    value: 9
                },
                {
                    label: "10",
                    value: 10
                },
                {
                    label: "11",
                    value: 11
                },
                {
                    label: "12",
                    value: 12
                },
                {
                    label: "13",
                    value: 13
                },
                {
                    label: "14",
                    value: 14
                },
                {
                    label: "15",
                    value: 15
                },
                {
                    label: "16",
                    value: 16
                },
                {
                    label: "17",
                    value: 17
                },
                {
                    label: "18",
                    value: 18
                },
                {
                    label: "19",
                    value: 19
                },
                {
                    label: "20",
                    value: 20
                },
                {
                    label: "21",
                    value: 21
                },
                {
                    label: "22",
                    value: 22
                },
                {
                    label: "23",
                    value: 23
                },
                {
                    label: "24",
                    value: 24
                },
                {
                    label: "25",
                    value: 25
                },
                {
                    label: "26",
                    value: 26
                },
                {
                    label: "27",
                    value: 27
                },
                {
                    label: "28",
                    value: 28
                },
                {
                    label: "29",
                    value: 29
                },
                {
                    label: "30",
                    value: 30
                }
            ],
            dailyemailReport: [],
            dailyemailReportoptions: [{
                    label: "10",
                    value: 10
                },
                {
                    label: "25",
                    value: 25
                },
                {
                    label: "50",
                    value: 50
                },
                {
                    label: "75",
                    value: 75
                },
                {
                    label: "100",
                    value: 100
                },
                {
                    label: "200",
                    value: 200
                }
            ],
            weeklyemailReport: [],
            weeklyemailReportoptions: [{
                    label: "10",
                    value: 10
                },
                {
                    label: "25",
                    value: 25
                },
                {
                    label: "50",
                    value: 50
                },
                {
                    label: "75",
                    value: 75
                },
                {
                    label: "100",
                    value: 100
                },
                {
                    label: "200",
                    value: 200
                }
            ],
            notificationRulePerPageValue: [
            {
                    label: "5",
                    value: 5
                },
                {
                    label: "10",
                    value: 10
                },
                {
                    label: "25",
                    value: 25
                },
                {
                    label: "50",
                    value: 50
                },
                {
                    label: "100",
                    value: 100
                },
            ],
            notificationRulePerPage: 5,
            /* Table */
            notifyUrl: [],

            showNotificationRulesWatchListCard:false,
            notificationRuleIsConsumer:false,
            showInternationlLink: false,
        };
    },
    mounted() {
        var that = this;
        window.addEventListener("focus", checkZohoStatus);

        function checkZohoStatus() {
            if (that.isEventListner) {
                that.getZohoStatusByUserID();
            }
            that.isEventListner = false;
        }
    },
    created() {
        let uid = this.$route.params.id;
        let VVtoken = localStorage.getItem("VVtoken");
        let token = JSON.parse(VVtoken);
        this.isReseller = token[uid].isReseller;
        this.isFreeTrial = token[uid].isActiveFreeTrial;
        this.isResellerClient = token[uid].userDetails.Role;
        this.isConsumerPlan=token[uid].isConsumerPlan
        this.vendastaIsPartner=token[uid].vendastaIsPartner
        this.isReportSettingsReadOnly = token[uid].isReportSettingsReadOnly;

        let screen = token[uid].screenItems;
        const CRMLinkresult = screen.find( ({ ScreenItemName }) => ScreenItemName === "configuration_users_details_crmintegration_link" );
        this.showCRMIntegration = CRMLinkresult.Visible;

        let menuItems = token[uid].menuItems
        for (let i = 0; i < menuItems.length; i++) {
          if(menuItems[i].MenuItemName === 'leadhandling-notificationrules')
            this.mainMenuIsLeadHandlingNotificationRule = menuItems[i].Visible

            if(menuItems[i].MenuItemName === 'leadhandling-territoryrules')
            this.mainMenuIsLeadHandlingTerritoryRule = menuItems[i].Visible
        }

        this.getUserCount();
        this.getUserList();
        this.getRoles();
        this.getTimeZones();
    },
    watch: {
        crmSystem(val) {
            if (val == "Zoho") {
                this.getZohoStatusByUserID();
            }
        },
        enableZapier(val) {
            if (val == true && (this.zapierKey == null || this.zapierKey == "")) {
                this.generateZapierKey();
            }
        },
    },
    methods: {
        //User Specific Notification Rule and Territory Rule
        async getLeadHandlingTabsInfo(id){
            this.$vs.loading();
            await this.axios
                .get("/ws/ConfigUser/GetLeadHandlingTabsInfo?userId="+id)
                .then((response) => {
                    let data = response.data;
                    let userSpecificGetLeadHandlingTabsInfoMenu = data.MenuItems;
                    for (let i = 0; i < userSpecificGetLeadHandlingTabsInfoMenu.length; i++) {
                        if(userSpecificGetLeadHandlingTabsInfoMenu[i].MenuItemName == "leadhandling-notificationrules" &&userSpecificGetLeadHandlingTabsInfoMenu[i].Visible == true){
                            this.isShowUserPecificNotificationRuleTab = true

                            let screen = data.ScreenItems;
                            const result = screen.find( ({ ScreenItemName }) => ScreenItemName === "leadhandling_notificationrules_watchlist_card" );
                            this.showNotificationRulesWatchListCard = result.Visible;

                            const result2 = screen.find( ({ ScreenItemName }) => ScreenItemName === "leadhandling_territoryrules_international_link" );
                            this.showInternationlLink = result2.Visible;
                        }
                        if(userSpecificGetLeadHandlingTabsInfoMenu[i].MenuItemName == "leadhandling-territoryrules" &&userSpecificGetLeadHandlingTabsInfoMenu[i].Visible == true){
                            this.isShowUserPecificTerritoryRuleTab = true
                        }
                    }
                    this.notificationRuleIsConsumer = data.Is_Consumer_Plan
                    this.$vs.loading.close();
                })
                .catch((e) => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        },
        //Notification Rule Tab
        async deleteNotifyUrl(url) {
            this.deleteUrl = url.Site_URL;
            this.deleteNotificationPopupActive = true;
        },
        // when click on cancel button
        async cancelCreate() {
            this.siteUrl = null;
            this.addpnewurlActive = false;
            setTimeout(() => {
                this.errors.clear();
            }, 50);
        },
        // check validation
        async notificationRuleCheckValidation() {
            this.$validator.validateAll().then(async result => {
                if (result) {
                    this.CreateNotificationRuleURL();
                }
            });
        },
        // get notification rule data
        async getNotificationRule(id) {
            this.$vs.loading();

            await this.axios
                .get("/ws/NotificationRule/GetNotificationRuleListForUser?userId="+id)
                .then(response => {
                    let data = response.data;
                    this.notificationEnabled = data.Notification_Enabled;
                    this.notifySendDailyReport = data.Notify_Send_Daily_Report;
                    this.notifySendWeeklyReport = data.Notify_Send_Weekly_Report;
                    this.notificationEnabledWatchList =
                        data.Notification_Enabled_Watch_List;
                    this.notificationURLEnabled = data.Notification_URL_Enabled;
                    this.notifyNumberPageVisits = data.Notify_Number_Page_Visits;
                    this.notificationDuplicateLeadDelay =
                        data.Notification_Duplicate_Lead_Delay;
                    this.notifySendDailyReportNumber =
                        data.Notify_Send_Daily_Report_Number;

                    this.notifySendWeeklyReportNumber =
                        data.Notify_Send_Weekly_Report_Number;
                    this.$vs.loading.close();
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        },
        // get notification rule url
        async getNotificationRuleUrl(id) {
            this.$vs.loading();

            await this.axios
                .get("/ws/NotificationRule/GetNotificationRuleURLListForUser?userId="+id)
                .then(response => {
                    let data = response.data;
                    this.notifyUrl = data;
                    this.$vs.loading.close();
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        },
        // create notification rule url
        async CreateNotificationRuleURL() {
            this.$vs.loading();
            let createNotificationUrl = {
                Site_URL: this.siteUrl,
                UserId: this.userId
            };
            await this.axios
                .post(
                    "/ws/NotificationRule/CreateNotificationRuleURL",
                    createNotificationUrl
                )
                .then(() => {
                    this.siteUrl = null;
                    setTimeout(() => {
                        this.errors.clear();
                    }, 100);
                    this.addpnewurlActive = false;
                    this.getNotificationRuleUrl(this.userId);
                    this.$vs.loading.close();
                    this.$vs.notify({
                        title: "Success",
                        text: "Notification rule url has been created successfully.",
                        color: "success",
                        iconPack: "feather",
                        icon: "icon-check",
                        position: "top-right",
                        time: 4000
                    });
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);

                    this.$vs.notify({
                        title: "Error",
                        text: e.response.data.Errors[0].Message,
                        color: "danger",
                        iconPack: "feather",
                        icon: "icon-alert-circle",
                        position: "top-right",
                        time: 4000
                    });
                });
        },
        // update notification rule data
        async updateNotificationRule() {
            let updateRules = {
                Notification_Enabled: this.notificationEnabled,
                Notify_Send_Daily_Report: this.notifySendDailyReport,
                Notify_Send_Weekly_Report: this.notifySendWeeklyReport,
                Notification_Enabled_Watch_List: this.notificationEnabledWatchList,
                Notification_URL_Enabled: this.notificationURLEnabled,
                Notify_Number_Page_Visits: this.notifyNumberPageVisits,
                Notification_Duplicate_Lead_Delay: this.notificationDuplicateLeadDelay,
                Notify_Send_Daily_Report_Number: this.notifySendDailyReportNumber,
                Notify_Send_Weekly_Report_Number: this.notifySendWeeklyReportNumber,
                UserId: this.userId
            };
            await this.axios
                .post("/ws/NotificationRule/UpdateNotificationRules", updateRules)
                .then(() => {
                    this.siteUrl = null;
                    setTimeout(() => {
                        this.errors.clear();
                    }, 100);
                    this.addpnewurlActive = false;
                    this.getNotificationRule(this.userId);
                    this.$vs.loading.close();
                    this.$vs.notify({
                        title: "Success",
                        text: "Your information has been updated successfully.",
                        color: "success",
                        iconPack: "feather",
                        icon: "icon-check",
                        position: "top-right",
                        time: 4000
                    });
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                    this.$vs.notify({
                        title: "Error",
                        text: e.response.data.Errors[0].Message,
                        color: "danger",
                        iconPack: "feather",
                        icon: "icon-alert-circle",
                        position: "top-right",
                        time: 4000
                    });
                });
        },
        // delete notification url
        async deleteNotificationUrl() {
            this.$vs.loading();
            let deleteNotificationUrl = {
                Site_URL: this.deleteUrl,
                UserId: this.userId
            };
            await this.axios
                .post(
                    "/ws/NotificationRule/DeleteNotificationRuleURL",
                    deleteNotificationUrl
                )
                .then(() => {
                    this.deleteUrl = null;

                    this.deleteNotificationPopupActive = false;
                    this.getNotificationRuleUrl(this.userId);
                    this.$vs.loading.close();
                    this.$vs.notify({
                        title: "Success",
                        text: "URL has been removed from your notification list.",
                        color: "success",
                        iconPack: "feather",
                        icon: "icon-check",
                        position: "top-right",
                        time: 4000
                    });
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);

                    this.$vs.notify({
                        title: "Error",
                        text: e.response.data.Errors[0].Message,
                        color: "danger",
                        iconPack: "feather",
                        icon: "icon-alert-circle",
                        position: "top-right",
                        time: 4000
                    });
                });
        },
        //Territory Tab
        /** when selectall states button is clicked will check all the checkbox */
        selectAllState() {
            this.states = [];
            this.statesAvailable.forEach(element => {
            this.states.push(element.code);
            });
        },
            /** when unselectall states button is clicked will check all the checkbox */
        unselectAllState() {
            this.states = [];
        },
            /** when unselectall countries button is clicked will check all the checkbox */
        unselectAllCountries() {
            this.countries = [];
        },
            /** when selectall countries button is clicked will check all the checkbox */
        selectAllCountries() {
            this.countries = [];
            this.countriesAvailable.forEach(element => {
            this.countries.push(element.code);
            });
        },
        /** Get the selected states value */
        async getNotificationTerritorySelectedStatesForUser(id) {
            this.$vs.loading();

            await this.axios
            .get("/ws/NotificationTerritory/GetNotificationTerritorySelectedStatesForUser?userId="+id)
            .then(response => {
                let data = response.data;

                this.states = data;
                this.$vs.loading.close();
            })
            .catch(e => {
                this.$vs.loading.close();
                this.showError(e);
            });
        },
        /** Get the selected countries value */
        async getNotificationTerritorySelectedCountriesForUser(id) {
            this.$vs.loading();
            await this.axios
            .get(
                "/ws/NotificationTerritory/GetNotificationTerritorySelectedCountriesForUser?userId="+id
            )
            .then(response => {
                let data = response.data;

                this.countries = data;
                this.$vs.loading.close();
            })
            .catch(e => {
                this.$vs.loading.close();
                this.showError(e);
            });
        },
        /** update states  */
        async updateUSTerritoryForUser() {
            this.$vs.loading();
            let payload = {
                UserId: this.userId,
                Territories: this.states
            }
            await this.axios
            .post("/ws/NotificationTerritory/UpdateUSTerritoryForUser", payload)
            .then(() => {
                this.getNotificationTerritorySelectedStatesForUser(this.userId);
                this.$vs.loading.close();
                this.$vs.notify({
                title: "Success",
                text: "Your information has been updated successfully.",
                color: "success",
                iconPack: "feather",
                icon: "icon-check",
                position: "top-right",
                time: 4000
                });
            })
            .catch(e => {
                this.$vs.loading.close();
                this.showError(e);

                this.$vs.notify({
                title: "Error",
                text: e.response.data.Errors[0].Message,
                color: "danger",
                iconPack: "feather",
                icon: "icon-alert-circle",
                position: "top-right",
                time: 4000
                });
            });
        },
        /** update country  */
        async updateCountryTerritoryForUser() {
            this.$vs.loading();
            let payload = {
                UserId: this.userId,
                Territories: this.countries
            }
            await this.axios
            .post(
                "/ws/NotificationTerritory/UpdateCountryTerritoryForUser",
                payload
            )
            .then(() => {
                this.getNotificationTerritorySelectedCountriesForUser(this.userId);
                this.$vs.loading.close();
                this.$vs.notify({
                title: "Success",
                text: "Your information has been updated successfully.",
                color: "success",
                iconPack: "feather",
                icon: "icon-check",
                position: "top-right",
                time: 4000
                });
            })
            .catch(e => {
                this.$vs.loading.close();
                this.showError(e);
                this.$vs.notify({
                title: "Error",
                text: e.response.data.Errors[0].Message,
                color: "danger",
                iconPack: "feather",
                icon: "icon-alert-circle",
                position: "top-right",
                time: 4000
                });
            });
        },
        /** cancel the changes done by user  states  */
        cancelStateChanges() {
        this.getNotificationTerritorySelectedStatesForUser(this.userId);
        this.$vs.notify({
            title: "Changes Reverted",
            text: "Your information has been restored to the original state.",
            color: "warning",
            iconPack: "feather",
            icon: "icon-alert-triangle",
            position: "top-right",
            time: 4000
        });
        },
        /** cancel the changes done by user  countries  */
        cancelCountriesChanges() {
            this.getNotificationTerritorySelectedCountriesForUser(this.userId);
            this.$vs.notify({
            title: "Changes Reverted",
            text: "Your information has been restored to the original state.",
            color: "warning",
            iconPack: "feather",
            icon: "icon-alert-triangle",
            position: "top-right",
            time: 4000
            });
        },
        closepopup() {
            this.popupActive = false;
        },
        //generate zapier key
        async generateZapierKey() {
            this.$vs.loading();
            await this.axios
                .get("/ws/ConfigUser/GenerateZapierKey")
                .then((response) => {
                    let data = response.data;
                    this.zapierKey = data;
                    this.$vs.loading.close();
                })
                .catch((e) => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        },
        //check validation for post back url
        async checkValidation() {
            this.$validator.validateAll("checkpostback").then(async (result) => {
                if (result) {
                    this.testPostBackUrl();
                }
            });
        },
        //when click on create user check validation on all tabs
        validateUser() {

            if (this.tabno == 0) {
                this.userValidation();
                if (
                    this.postback &&
                    (this.postbackurl == "" || this.postbackurl == null)
                ) {
                    this.tabno = 2;
                    setTimeout(() => {
                        this.checkValidation();
                    }, 200);
                } else {
                    this.createUser();
                }
            } else if (this.tabno == 1) {
                this.crmValidate();
                if (
                    this.postback &&
                    (this.postbackurl == "" || this.postbackurl == null)
                ) {
                    this.tabno = 2;
                    setTimeout(() => {
                        this.checkValidation();
                    }, 200);
                } else if (
                    this.firstName == "" ||
                    this.lastName == "" ||
                    this.email == "" ||
                    this.phone == "" ||
                    this.password == "" ||
                    this.cpassword == "" ||
                    this.firstName == null ||
                    this.lastName == null ||
                    this.email == null ||
                    this.phone == null ||
                    this.password == null ||
                    this.cpassword == null
                ) {
                    this.tabno = 0;
                    setTimeout(() => {
                        this.userValidation();
                    }, 200);
                } else {
                    this.createUser();
                }
            } else {
                if (this.postback) {
                    this.checkValidation();
                } else {
                    if (
                        this.firstName == "" ||
                        this.lastName == "" ||
                        this.email == "" ||
                        this.phone == "" ||
                        this.password == "" ||
                        this.cpassword == "" ||
                        this.firstName == null ||
                        this.lastName == null ||
                        this.email == null ||
                        this.phone == null ||
                        this.password == null ||
                        this.cpassword == null
                    ) {
                        this.tabno = 0;
                        setTimeout(() => {
                            this.userValidation();
                        }, 200);
                    } else {
                        this.createUser();
                    }
                }
            }
        },
        //check validation crm tab
        crmValidate() {
            this.$validator.validateAll("crminfo").then(() => {});
        },
        //check validation on information tab
        userValidation() {
            this.$validator.validateAll("userinfo").then((result) => {
                if (result) {
                    this.userV = true;
                    //this.createUser();
                } else {
                    this.userV = false;
                    this.isCreateUpdateUser = false;
                }
            });
            // }
        },
        // call api for test post back url
        async testPostBackUrl() {
            this.$vs.loading();
            let input = {
                First_Name: this.firstName,
                Last_Name: this.lastName,
                Email: this.email,
                Phone: this.phone,
                Password_New: this.password,
                Password_Confirm: this.cpassword,
                Time_Zone_Id: this.timeZone,
                IsActive: this.active,
                Id: this.userId ? this.userId : "",
                Role_Id: this.role,

                CRM_Type: this.crmSystem,
                CRM_URL: this.crmurl,
                CRM_Key: this.securitytoken,
                CRM_User_Id: this.username,
                CRM_Password: this.crmPassword,
                CRM_Enabled: this.enableCrm,
                CRM_WSDL: null,
                CRM_AutoSend: false,
                CRM_Push_Tagged_Users: this.CRM_Push_Tagged_Users,
                CRM_Push_General_Companies: this.CRM_Push_General_Companies,

                Post_Back_User: this.postback,
                Post_Back_URL: this.postbackurl,
                Zapier_Enabled: this.enableZapier,
                Zapier_Key: this.zapierKey,

                Default_User: false,
                Prevent_Delete: false,
                Last_Login: new Date(),
                Date_Created: new Date(),
                Date_Updated: new Date(),
                Updated_By: null,
                IsDeleted: false,
                Role_Name: "",
                Notify_URL: null,
                Notification_URL_Enabled: false,
            };
            await this.axios
                .post("/ws/ConfigUser/TestHttpPostBack", input)
                .then((response) => {
                    let data = response.data;
                    if(data){
                        this.postBackURLError = false;
                        this.postBackURLErrorMessage ="";
                        if(this.isCreateUpdateUser){
                             if (
                        this.firstName == "" ||
                        this.lastName == "" ||
                        this.email == "" ||
                        this.phone == "" ||
                        this.password == "" ||
                        this.cpassword == "" ||
                        this.firstName == null ||
                        this.lastName == null ||
                        this.email == null ||
                        this.phone == null ||
                        this.password == null ||
                        this.cpassword == null
                    ) {
                        this.tabno = 0;
                        setTimeout(() => {
                            this.userValidation();
                        }, 200);
                    } else {
                        this.createUser();
                    }
                        }
                    }

                    this.$vs.loading.close();
                })
                .catch((e) => {
                    this.$vs.loading.close();
                    this.postBackURLError = true;
                    this.postBackURLErrorMessage = e.response.data.Errors[0].Message;
                    this.isCreateUpdateUser = false;
                    this.showError(e);
                });
        },
        // Edit User when click on edit
        async editUser(id) {
            this.$vs.loading();
            await this.axios
                .get("ws/ConfigUser/GetUser?id=" + id)
                .then((response) => {
                    let uid = this.$route.params.id;
                    let jwtDecode = VueJwtDecode.decode(
                        this.$store.state.authTokenVV[uid].token
                    );
                    this.disableEmail = (jwtDecode.initialRole == 'Global Admin') ? false : true;
                    this.tabno = 0;
                    let data = response.data;
                    this.firstName = data.First_Name;
                    this.lastName = data.Last_Name;
                    this.email = data.Email;
                    this.phone = data.Phone;
                    this.password = data.Password_New;
                    this.cpassword = data.Password_Confirm;
                    this.timeZone = data.Time_Zone_Id;
                    this.active = data.IsActive;
                    this.role = data.Role_Id;
                    this.userId = data.Id;
                    this.enableCrm = data.CRM_Enabled;

                    this.crmSystem = data.CRM_Type;
                    this.crmurl = data.CRM_URL;
                    this.securitytoken = data.CRM_Key;
                    this.crmPassword = data.CRM_Password;
                    this.username = data.CRM_User_Id;
                    this.CRM_Push_Tagged_Users = data.CRM_Push_Tagged_Users;
                    this.CRM_Push_General_Companies = data.CRM_Push_General_Companies;

                    this.postback = data.Post_Back_User;
                    this.postbackurl = data.Post_Back_URL;
                    this.enableZapier = data.Zapier_Enabled;
                    this.zapierKey = data.Zapier_Key;
                    this.defaultUser = data.Default_User;
                    this.addUserActive = true;
                    this.crmsystemoptions.push({
                        value: "Zoho",
                        text: "Zoho",
                    });
                    this.$vs.loading.close();
                })
                .catch((e) => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        },
        // get user count
        async getUserCount() {
            this.$vs.loading();
            await this.axios
                .get("/ws/ConfigUser/GetUserCounts")
                .then((response) => {
                    let data = response.data;
                    this.userCount = data;
                    this.$vs.loading.close();
                })
                .catch((e) => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        },
        //get user list user list api
        async getUserList() {
            this.$vs.loading();
            await this.axios
                .get("/ws/ConfigUser/GetUserList")
                .then((response) => {
                    let data = response.data;
                    this.userinfo = data;
                    this.$vs.loading.close();
                })
                .catch((e) => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        },
        //get roles list
        async getRoles() {
            this.$vs.loading();
            await this.axios
                .get("/ws/ConfigUser/GetAvailableRoles")
                .then((response) => {
                    let data = response.data;
                    this.roleoptions = data;
                    this.role = "4c917fc4875f000000001dce";
                    this.$vs.loading.close();
                })
                .catch((e) => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        },
        //get time zode list
        async getTimeZones() {
            this.$vs.loading();

            await this.axios
                .get("/ws/MyInfo/GetTimeZones")
                .then((response) => {
                    let data = response.data;
                    this.timeZoneoptions = data;
                    this.$vs.loading.close();
                })
                .catch((e) => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        },
        // create user api
        async createUser() {
            let input = {
                First_Name: this.firstName,
                Last_Name: this.lastName,
                Email: this.email,
                Phone: this.phone,
                Password_New: this.password,
                Password_Confirm: this.cpassword,
                Time_Zone_Id: this.timeZone,
                IsActive: this.active,
                Id: this.userId ? this.userId : "",
                Role_Id: this.role,

                CRM_Type: this.crmSystem,
                CRM_URL: this.crmurl,
                CRM_Key: this.securitytoken,
                CRM_User_Id: this.username,
                CRM_Password: this.crmPassword,
                CRM_Enabled: this.enableCrm,
                CRM_WSDL: null,
                CRM_AutoSend: false,
                CRM_Push_Tagged_Users: this.CRM_Push_Tagged_Users,
                CRM_Push_General_Companies: this.CRM_Push_General_Companies,

                Post_Back_User: this.postback,
                Post_Back_URL: this.postbackurl,
                Zapier_Enabled: this.enableZapier,
                Zapier_Key: this.zapierKey,

                Default_User: false,
                Prevent_Delete: false,
                Last_Login: new Date(),
                Date_Created: new Date(),
                Date_Updated: new Date(),
                Updated_By: null,
                IsDeleted: false,
                Role_Name: "",
                Notify_URL: null,
                Notification_URL_Enabled: false,
            };
            let url = this.userId ?
                "/ws/ConfigUser/UpdateUser" :
                "/ws/ConfigUser/CreateUser";
            await this.axios
                .post(url, input)
                .then(() => {
                    setTimeout(() => {
                        this.errors.clear();
                    }, 100);
                    this.closePopupUser();
                    this.getUserCount();
                    this.getUserList();
                    this.$vs.loading.close();
                })
                .catch((e) => {
                    this.$vs.loading.close();
                    this.isCreateUpdateUser = false;
                    if (
                        e.response.data.Errors[0].Key == "CRM_Type" ||
                        e.response.data.Errors[0].Key == "CRM_Key" ||
                        e.response.data.Errors[0].Key == "CRM_URL" ||
                        e.response.data.Errors[0].Key == "CRM_User_Id" ||
                        e.response.data.Errors[0].Key == "CRM_Password"
                    ) {
                        this.tabno = 1;
                        setTimeout(() => {
                            this.crmValidate();
                        }, 200);
                    }
                    this.$vs.notify({
                        title: "Error",
                        text: e.response.data.Errors[0].Message,
                        color: "danger",
                        iconPack: "feather",
                        icon: "icon-alert-circle",
                        position: "top-right",
                        time: 4000,
                    });
                    this.showError(e);
                });
        },
        // mark user as default
        async makeDefaultUser() {
            let input = {
                First_Name: this.firstName,
                Last_Name: this.lastName,
                Email: this.email,
                Phone: this.phone,
                Password_New: this.password,
                Password_Confirm: this.cpassword,
                Time_Zone_Id: this.timeZone,
                IsActive: this.active,
                Id: this.userId ? this.userId : "",
                Role_Id: this.role,

                CRM_Type: this.crmSystem,
                CRM_URL: this.crmurl,
                CRM_Key: this.securitytoken,
                CRM_User_Id: this.username,
                CRM_Password: this.crmPassword,
                CRM_Enabled: this.enableCrm,
                CRM_WSDL: null,
                CRM_AutoSend: false,
                CRM_Push_Tagged_Users: this.CRM_Push_Tagged_Users,
                CRM_Push_General_Companies: this.CRM_Push_General_Companies,

                Post_Back_User: this.postback,
                Post_Back_URL: this.postbackurl,
                Zapier_Enabled: this.enableZapier,
                Zapier_Key: this.zapierKey,

                Default_User: true,
                Prevent_Delete: false,
                Last_Login: new Date(),
                Date_Created: new Date(),
                Date_Updated: new Date(),
                Updated_By: null,
                IsDeleted: false,
                Role_Name: "",
                Notify_URL: null,
                Notification_URL_Enabled: false,
            };
            await this.axios
                .post("/ws/ConfigUser/MakeDefaultUser", input)
                .then(() => {
                    setTimeout(() => {
                        this.errors.clear();
                    }, 100);
                    this.closePopupUser();
                    this.getUserList();
                    this.$vs.loading.close();
                })
                .catch((e) => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        },
        // open confirmation for deleting
        deleteUser(id, email) {
            this.userId = id;
            this.email = email;
            this.deletepopupActive = true;
        },
        //delete user
        async deleteUserConfirm() {
            this.$vs.loading();
            await this.axios
                .get("/ws/ConfigUser/DeleteUser?id=" + this.userId)
                .then(() => {
                    this.userId = null;
                    this.email = null;
                    this.deletepopupActive = false;
                    this.getUserList();
                    this.getUserCount();
                    this.$vs.loading.close();
                })
                .catch((e) => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        },
        // when we click on cancel button
        closePopupUser() {
            this.disableEmail = false;
            this.addUserActive = false;
            this.tabno = 0;
            this.firstName = null;
            this.lastName = null;
            this.email = null;
            this.phone = null;
            this.password = null;
            this.cpassword = null;
            this.timeZone = null;
            this.active = true;
            this.userId = null;
            this.role = null;

            this.crmSystem = null;
            this.crmurl = null;
            this.securitytoken = null;
            this.username = null;
            this.crmPassword = null;
            this.enableCrm = false;

            this.CRM_Push_Tagged_Users = false;
            this.CRM_Push_General_Companies = false;

            this.postback = false;
            this.postbackurl = null;
            this.enableZapier = false;
            this.zapierKey = null;
            this.defaultUser = false;
            this.isCreateUpdateUser = false;
            setTimeout(() => {
                this.errors.clear();
            }, 100);
            this.crmsystemoptions = [];
            this.crmsystemoptions = [{
                    value: "ConnectWise",
                    text: "ConnectWise",
                },
                {
                    value: "LeadMaster",
                    text: "LeadMaster",
                },
                {
                    value: "SalesForce.com",
                    text: "SalesForce.com",
                },
                {
                    value: "SalesNexus",
                    text: "SalesNexus",
                },
            ];
            //User Specific Notification Rule and Territory Rule
            // this.isShowUserPecificNotificationRuleTab = false;
            // this.isShowUserPecificTerritoryRuleTab = false;
            //Territory Tab
            this.states = [];
            this.countries = [];
            //Notification Rule
            this.notificationRulePerPage = 5;
        },
        // In crm connect zoho
        async getZohoStatusByUserID() {
            this.$vs.loading();
            await this.axios
                .get(
                    "/ws/CRMIntegration/GetZohoCRMIntegrationStatusByUserid?id=" +
                    this.userId
                )
                .then((response) => {
                    let data = response.data;
                    this.zohoStatus = data;
                    //this.watchlist = data;
                    this.$vs.loading.close();
                })
                .catch((e) => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        },
        // link to zoho account
        async linkZohoAccount() {
            this.$vs.loading();
            let input = {
                ServiceTypeInfo: "zohocrm",
                LocationId: 0,
            };
            await this.axios
                .post(
                    "/ws/CRMIntegration/RequestExternalLinkForAuthUpdateById?id=" +
                    this.userId,
                    input
                )
                .then((response) => {
                    let data = response.data;
                    this.isEventListner = true;
                    window
                        .open(
                            data,
                            +"Zoho Account",
                            "toolbar=0,scrollbars=1,location=0,status=0,menubar=0,resizable=1,width=650, height=550,left = 300,top=100"
                        )
                        .focus();
                    this.$vs.loading.close();
                })
                .catch((e) => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        },
        // delete zoho account
        async deleteZohoAccount() {
            this.$vs.loading();
            await this.axios
                .get(
                    "/ws/CRMIntegration/DeleteZohoCRMIntegrationConnectionByUserId?id=" +
                    this.userId
                )
                .then(() => {
                    this.getZohoStatus();
                    this.$vs.loading.close();
                })
                .catch((e) => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        },
    },
    computed: {
        isTableRecordEntriesDisplay() {
            if (this.userinfo.length > 0) {
                if (this.$refs.tableData.datax.length > 0) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        },
        isSearch: function () {
            if (this.userinfo.length > 0) {
                if (
                    this.$refs.tableData.searchx == null ||
                    this.$refs.tableData.searchx == ""
                ) {
                    return false;
                } else {
                    return true;
                }
            }
        },
        showingDataFrom: function () {
            if (this.userinfo.length > 0) {
                return (this.$refs.tableData.currentx - 1) * this.perPage + 1;
            }
        },
        showingDataTo: function () {
            if (this.userinfo.length > 0) {
                let totalPages = this.$refs.tableData.getTotalPages;
                if (totalPages == this.$refs.tableData.currentx) {
                    return (
                        (this.$refs.tableData.currentx - 1) * this.perPage +
                        this.$refs.tableData.datax.length
                    );
                } else {
                    return this.$refs.tableData.currentx * this.perPage;
                }
            }
        },
        totalSearchedData: function () {
            if (this.userinfo.length > 0) {
                return this.$refs.tableData.queriedResults.length;
            }
        },
        showingSearchedDataTo: function () {
            if (this.userinfo.length > 0) {
                let totalPages = this.$refs.tableData.getTotalPagesSearch;
                if (totalPages == this.$refs.tableData.currentx) {
                    return (
                        (this.$refs.tableData.currentx - 1) * this.perPage +
                        this.$refs.tableData.datax.length
                    );
                } else {
                    return this.$refs.tableData.currentx * this.perPage;
                }
            }
        },
    },
};
</script>
